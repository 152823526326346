import {Router} from "@reach/router"
import {useI18next} from "gatsby-plugin-react-i18next"
import React from "react"
import {LoginPage} from "../features/profile/LoginPage"
import {UserProfilePage} from "../features/profile/ProfilePage"
import {PrivateRouteRedux} from "../components/PrivateRoute"
import {PostEdit} from "../features/blog/PostEdit"
import {PrivateRoutePageRedux} from "../components/PrivateRoutePage"
import {WriterBlog} from "../features/blog/WriterBlogPage"

const PlusPage = () => {
  const i18next = useI18next()
  const prefix = i18next.defaultLanguage == i18next.language ? "" : `${i18next.language}/`
  return (
    <Router basepath={`/${prefix}`}>
      <LoginPage.Page path="/plus/login"/>
      <PrivateRouteRedux component={UserProfilePage} path="/plus/connect/users/:userId"/>
      {/*<PrivateRouteRedux component={UsersPage} path="/plus/connect/users"/>
      <PrivateRouteRedux component={TopicPageRedux} path="/plus/topic/:topicId"/>

      <PrivateRouteRedux component={DashboardRedux} path="/plus/dashboard"/>
      <PrivateRouteRedux component={MarketplacePage} path="/plus/marketplace"/>
      <PrivateRouteRedux component={AddItemPage} path="/plus/marketplace/add"/>

      <PrivateRouteRedux component={MyProfilePageRedux} path="/plus/profile"/>
      <PrivateRouteRedux component={MyRecalboxPageRedux} path="/plus/myrecalbox/systems" myPage={"systems"}/>

      <PrivateRouteRedux component={MyRecalboxPageRedux} path="/plus/myrecalbox/share" myPage={"share"}/>
      <PrivateRouteRedux component={MyRecalboxPageRedux} path="/plus/myrecalbox/share/category/:elementId"
                         myPage={"sharecategory"}/>
      <PrivateRouteRedux component={MyRecalboxPageRedux} path="/plus/myrecalbox/share/item/:elementId" myPage={"shareitem"}/>
*/}
      <PrivateRoutePageRedux page={PostEdit} path={PostEdit.getUrl(":postI18nId", ":lang")}/>
      <PrivateRoutePageRedux page={PostEdit} path={PostEdit.getUrl(":postI18nId")}/>
      <PrivateRoutePageRedux page={PostEdit} path={PostEdit.getUrl()}/>

      <PrivateRoutePageRedux page={WriterBlog} path={WriterBlog.getUrl()}/>

    </Router>
  )
}
export default PlusPage
